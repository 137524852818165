import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderSuccess = () => {
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied to clipboard!`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };

  const whatsappNumber = "9994005141";
  const whatsappLink = `https://wa.me/${whatsappNumber}`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <ToastContainer /> {/* Toast container must be included */}
      <img
        src={require("../assets/success.gif")}
        alt="Order Success"
        style={{ width: "250px", marginBottom: "20px" }}
      />
      <Typography variant="h4" gutterBottom color={"#019863"}>
        Order Placed Successfully!
      </Typography>
      <p style={{ marginTop: 5, marginBottom: 5 }}>
        Pay the amount to this UPI ID and send the screenshot to the WhatsApp
        number below.
      </p>
      <p
        style={{
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        UPI ID -{" "}
        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
          mahaarjun555-3@okhdfcbank
        </span>
        <IconButton
          onClick={() => copyToClipboard("mahaarjun555-3@okhdfcbank")}
        >
          <ContentCopyIcon />
        </IconButton>
      </p>
      <p
        style={{
          marginTop: 5,
          marginBottom: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        WhatsApp -{" "}
        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#019863", textDecoration: "underline" }}
          >
            {whatsappNumber}
          </a>
        </span>
        <IconButton onClick={() => copyToClipboard(whatsappNumber)}>
          <ContentCopyIcon />
        </IconButton>
      </p>
      <Typography variant="body1">Thank you for your purchase</Typography>
      <Button
        style={{
          marginTop: 30,
          backgroundColor: "#019863",
          padding: 10,
          border: "1px solid white",
          color: "white",
          fontWeight: "bold",
          borderRadius: 15,
        }}
        onClick={() => navigate("/")}
      >
        Return to home
      </Button>
    </Box>
  );
};

export default OrderSuccess;
