import React, { useEffect, useState } from "react";
import CarouselHome from "../Components/CarouselHome";
import ProductList from "./Product";
import ShimmerCarousel from "../ShimmerComponent/ShimmerCarousel";
import ShimmerProductList from "../ShimmerComponent/ShimmerProductList";
import "../cssStyles/MainPage.scss";
import { fetchProducts } from "../Redux/reducers/productReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../Redux/reducers/cartReducer";
import Map from "../Components/Map";
import { isAuthenticatedFunc } from "../Redux/reducers/authReducer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const { showShimmer, items } = useSelector((state) => state.products);
  const { cartItems } = useSelector((state) => state.cart);
  const { showMap } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (items.length <= 0) {
      dispatch(fetchProducts());
    }
    isAuthenticate();
  }, [dispatch]);

  const isAuthenticate = async () => {
    let token = await localStorage.getItem("token");
    if (token) {
      dispatch(isAuthenticatedFunc(true));
      if (cartItems.length <= 0) {
        dispatch(fetchCart());
      }
    }
  };

  return (
    <div className="homepage-container">
      {showMap ? (
        <Map />
      ) : (
        <div>
          {/* Carousel and Product List */}
          <div> {showShimmer ? <ShimmerCarousel /> : <CarouselHome />}</div>
          <div>{showShimmer ? <ShimmerProductList /> : <ProductList />}</div>
          {/* Order form will appear after the products */}
        </div>
      )}
    </div>
  );
}

export default HomePage;
