import React from "react";

const ShimmerCarousel = () => {
  return (
    <div className="shimmer-wrapper">
      <div
        className="shimmer"
        style={{ height: "300px", borderRadius: "10px" }}
      ></div>
    </div>
  );
};

export default ShimmerCarousel;
