// src/redux/authSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "./cartReducer";
import { FlashAuto } from "@mui/icons-material";

// Define the initial state for authentication
const initialState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  openLoginModal: false,
  showMap: false,
};

// Async thunk for login
export const handleLoginAction = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/login`,
        credentials
      );
      const { token, user } = response.data;
      localStorage.setItem("token", token); // Store token in localStorage
      return { user, token };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add an item to the cart
export const addAddress = createAsyncThunk(
  "auth/addAddress",
  async (data, { dispatch, rejectWithValue }) => {
    // Send the POST request with the Authorization header
    console.log(data);
    const response = await axios.post(
      `${API_BASE_URL}/address/add`,
      data.addressData,
      {
        headers: {
          Authorization: `Bearer ${data.token}`, // Include the token in the Authorization header
        },
      }
    );
    if (response.status === 200) {
      dispatch(showMapFunc(false));
    }
    return response.data.message;
  }
);

// Create a slice for authentication
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token"); // Remove token from localStorage
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },
    openLoginModalFunc: (state, action) => {
      console.log(action.payload, "payload");
      state.openLoginModal = action.payload;
    },
    showMapFunc: (state, action) => {
      state.showMap = action.payload;
    },
    isAuthenticatedFunc: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(handleLoginAction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
      })
      .addCase(handleLoginAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, openLoginModalFunc, showMapFunc, isAuthenticatedFunc } =
  authSlice.actions;

export default authSlice.reducer;
