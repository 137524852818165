import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0b3d0b;
  padding: 20px; /* Add padding for small screens */
`;

const PlansWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px; /* Add gap between cards */
`;

const PlanCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    /* Stack cards vertically on small screens */
    max-width: 100%;
    height: auto; /* Allow height to adjust based on content */
  }
`;

const PlanTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const PlanPrice = styled.p`
  font-size: 32px;
  margin-bottom: 20px;
  color: #1e3c72;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
  flex-grow: 1;
`;

const Feature = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const Button = styled.button`
  background-color: #1e3c72;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #2a5298;
  }
`;

const SubscriptionPlans = () => {
  const plans = [
    {
      title: "Basic",
      price: "$9.99/month",
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
    {
      title: "Standard",
      price: "$19.99/month",
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
    {
      title: "Premium",
      price: "$29.99/month",
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
  ];

  return (
    <Container>
      <PlansWrapper>
        {plans.map((plan, index) => (
          <PlanCard key={index}>
            <div>
              <PlanTitle>{plan.title}</PlanTitle>
              <PlanPrice>{plan.price}</PlanPrice>
            </div>
            <PlanFeatures>
              {plan.features.map((feature, idx) => (
                <Feature key={idx}>{feature}</Feature>
              ))}
            </PlanFeatures>
            <ButtonWrapper>
              <Button>Choose Plan</Button>
            </ButtonWrapper>
          </PlanCard>
        ))}
      </PlansWrapper>
    </Container>
  );
};

export default SubscriptionPlans;
