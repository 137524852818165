import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "./CustomModal"; // Import the custom modal
import { Button } from "@mui/material";
import { openLoginModalFunc } from "../Redux/reducers/authReducer";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { openLoginModal, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleCartButton = async () => {
    navigate("/Cart");
  };

  const loginModal = () => {
    dispatch(openLoginModalFunc(false));
  };
  const loginModalOpen = () => {
    dispatch(openLoginModalFunc(true));
  };

  const handleSendOtp = () => {};

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["Home", "Products", "About Us", "Contact"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar style={{ backgroundColor: "white" }}>
        <Box sx={{ padding: "0 10%" }}>
          <Toolbar>
            {/* <Typography
              variant="h4"
              sx={{
                flexGrow: 1,
                color: "black",
                fontWeight: "bold",
                fontSize: 20,
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Green Sack
            </Typography> */}
            <div style={{ flexGrow: 1 }}>
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={require("../assets/greenSackLogo.png")}
                style={{ width: 130 }}
              />
            </div>
            <IconButton
              sx={{ flexGrow: 0.02 }}
              onClick={handleCartButton}
              color="black"
            >
              <Badge badgeContent={cartItems?.length} color="error">
                <ShoppingCartIcon style={{ color: "black" }} />
              </Badge>
            </IconButton>
          </Toolbar>
        </Box>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>

      {/* Custom Modal */}
      <CustomModal
        open={openLoginModal}
        onClose={loginModal}
        onSendOtp={handleSendOtp}
      />
    </div>
  );
};

export default Header;
