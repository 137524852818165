// src/store.js
import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import authReducer from "../reducers/authReducer"; // Import the auth reducer
import productReducer from "../reducers/productReducer";
import cartReducer from "../reducers/cartReducer";

// Create a logger middleware instance
const logger = createLogger({
  collapsed: true, // Collapse actions in the log
  duration: true, // Log the duration of each action
  diff: true, // Show the difference between previous and next state
});

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  products: productReducer,
  cart: cartReducer,
});

// Create the Redux store with middleware
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, logger)) // Add logger here
);

export default store;
