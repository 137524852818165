import React, { useState, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "../cssStyles/MapStyles.scss"; // Import your custom CSS file
import { useDispatch } from "react-redux";
import { addAddress } from "../Redux/reducers/authReducer";
// Google Maps API key (replace with your own)
const GOOGLE_MAPS_API_KEY = "AIzaSyB-Fl4s3yj-0elzsCIIPetgRu8lviB4Dtk";

// Default map center location
const DEFAULT_LOCATION = {
  lat: 11.04876842303815,
  lng: 77.02524616471406,
};

// Geocode address from latitude and longitude
const getAddressFromCoordinates = (lat, lng, setAddress, setPincode) => {
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ location: { lat, lng } }, (results, status) => {
    if (status === "OK" && results[0]) {
      const formattedAddress = results[0].formatted_address;
      const postalCodeComponent = results[0].address_components.find(
        (component) => component.types.includes("postal_code")
      );
      const pincode = postalCodeComponent
        ? postalCodeComponent.long_name
        : "Pincode not found";

      setAddress(formattedAddress);
      setPincode(pincode);
    } else {
      console.error("Geocoder failed due to:", status);
    }
  });
};

// Map component
const MapComponent = ({ location, onMapClick }) => (
  <GoogleMap
    mapContainerClassName="map-container"
    center={location}
    zoom={12}
    onClick={onMapClick}
  >
    <Marker position={location} />
  </GoogleMap>
);

// Work Type Selector component
const WorkTypeSelector = ({ selectedWorkType, setSelectedWorkType }) => {
  const workTypes = ["Home", "Work", "Other"];

  return (
    <div className="work-type-selector">
      <span className="label">Work Type:</span>
      <div className="work-types">
        {workTypes.map((type) => (
          <div
            key={type}
            className={`work-type-option ${
              selectedWorkType === type ? "selected" : ""
            }`}
            onClick={() => setSelectedWorkType(type)}
          >
            {type}
          </div>
        ))}
      </div>
    </div>
  );
};

// Main component
const Map = () => {
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("Home");
  const dispatch = useDispatch();
  // Handle map click event to update marker position and get address
  const handleMapClick = useCallback((e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLocation({ lat, lng });
    getAddressFromCoordinates(lat, lng, setAddress, setPincode);
  }, []);

  // Post address data
  const handleConfirmAddress = async () => {
    const token = await localStorage.getItem("token");
    const addressData = {
      type: selectedWorkType,
      address,
      lat: location.lat,
      lng: location.lng,
      pincode,
    };

    console.log("Address Data:", { addressData: addressData });
    // Handle further logic with addressData
    dispatch(addAddress({ addressData: addressData, token }));
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <MapComponent location={location} onMapClick={handleMapClick} />
      <div className="address-info">
        <div>
          <span className="label">Selected Address:</span> {address}
        </div>
        <div style={{ marginTop: 20 }}>
          <span className="label">Pincode:</span> {pincode}
        </div>
      </div>
      <WorkTypeSelector
        selectedWorkType={selectedWorkType}
        setSelectedWorkType={setSelectedWorkType}
      />
      <button className="confirm-button" onClick={handleConfirmAddress}>
        Confirm Address
      </button>
    </LoadScript>
  );
};

export default Map;
