import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import "../cssStyles/commonStyle.scss";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 15px;
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Price = styled.div`
  color: #a1824a;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Quantity = styled.div`
  color: #555;
  margin: 0 10px;
`;

const CartItem = ({
  image,
  title,
  price,
  quantity,
  addToCart,
  removeFromCart,
  isItemInCart,
}) => {
  return (
    <ItemContainer>
      <Image src={image} alt={title} />
      <Details>
        <Box>
          <Title>{title}</Title>
          <Price>₹ {price?.toFixed(2)}</Price>
        </Box>
        <QuantityContainer>
          {isItemInCart ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={removeFromCart} style={{ padding: 0 }}>
                <RemoveIcon />
              </IconButton>
              <Typography variant="body1" sx={{ mx: 1 }}>
                {quantity}
              </Typography>
              <IconButton onClick={addToCart} style={{ padding: 0 }}>
                <AddIcon />
              </IconButton>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#019863" }}
              sx={{ textTransform: "none" }}
              onClick={addToCart}
            >
              Add
            </Button>
          )}
        </QuantityContainer>
      </Details>
    </ItemContainer>
  );
};

export default CartItem;
