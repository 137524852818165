import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ProductCard from "../Components/ProductCard";
import {
  addToCart,
  addToCartInternalReduxState,
  removeFromCartInternalReduxState,
} from "../Redux/reducers/cartReducer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  justify-content: center;
`;

const TitleSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const ProductList = () => {
  const dispatch = useDispatch();
  const { items, error } = useSelector((state) => state.products);
  const { cartItems } = useSelector((state) => state.cart); // Adjust based on your Redux state

  const handleAddToCart = async (
    product,
    totalQuantity,
    maxMeasurement,
    measurement
  ) => {
    if (totalQuantity * measurement !== maxMeasurement) {
      let insertProduct = {
        ...product,
        quantity:
          totalQuantity == undefined
            ? 1
            : totalQuantity === 0
            ? 1
            : totalQuantity + 1,
      };
      dispatch(addToCartInternalReduxState(insertProduct));
    } else {
      toast.info(
        `You have reached the maximum limit to add particular product in cart`,
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  const handleRemoveFromCart = (product, totalQuantity) => {
    let insertProduct = {
      ...product,
      quantity:
        totalQuantity == undefined
          ? 1
          : totalQuantity === 0
          ? 1
          : totalQuantity,
    };

    dispatch(addToCart(insertProduct));
    dispatch(
      removeFromCartInternalReduxState({ insertProduct, totalQuantity })
    );
  };

  const isItemAddedToCart = (productStockId) => {
    const item = cartItems?.find((item) => item.stockId === productStockId);
    return item;
  };

  const findQuantityFromCart = (productStockId) => {
    const item = cartItems?.filter((item) => item.stockId === productStockId);
    return item[0]?.quantity;
  };

  return (
    <div>
      {error && <p>{error}</p>}
      {!error && (
        <TitleSearchWrapper>
          <Title>{"Shop All Vegetables"}</Title>
        </TitleSearchWrapper>
      )}
      <ToastContainer />
      <ProductListWrapper>
        {items?.map((product, key) => (
          <React.Fragment key={key}>
            <ProductCard
              key={product.stockId} // Use the stockId as the unique key
              title={product.name}
              tname={product.tname}
              price={product.price}
              image={product.image}
              available={product.available}
              measurement={product.measurement}
              maxMeasurement={product.maxMeasurement}
              unit={product.unit}
              addToCart={() =>
                handleAddToCart(
                  product,
                  findQuantityFromCart(product.stockId),
                  product.maxMeasurement,
                  product.measurement
                )
              }
              removeFromCart={() =>
                handleRemoveFromCart(
                  product,
                  findQuantityFromCart(product.stockId)
                )
              }
              quantity={findQuantityFromCart(product.stockId)}
              isItemInCart={
                findQuantityFromCart(product.stockId) > 0
                  ? isItemAddedToCart(product.stockId)
                  : false
              }
            />
          </React.Fragment>
        ))}
      </ProductListWrapper>
    </div>
  );
};

export default ProductList;
