import React from "react";

const ShimmerProductList = () => {
  const shimmerItems = Array.from({ length: 20 }).map((_, index) => (
    <div
      key={index}
      className="shimmer"
      style={{
        width: "135px",
        height: "150px",
        marginBottom: "10px",
        marginRight: 11.1,
      }}
    ></div>
  ));

  return <div className="shimmer-wrapper product-row">{shimmerItems}</div>;
};

export default ShimmerProductList;
