import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Box, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ProductCard = ({
  title,
  tname,
  price,
  image,
  addToCart,
  removeFromCart,
  quantity,
  isItemInCart,
  available,
  measurement,
  maxMeasurement,
  unit
}) => {
  const isSmallDevice = useMediaQuery("(max-width:500px)");
  const isVerySmallDevice = useMediaQuery("(max-width:360px)");

  // Define the card width and other styles based on screen size
  const cardWidth = isVerySmallDevice ? 140 : isSmallDevice ? 160 : 180;
  const boxWidth = isVerySmallDevice ? 140 : isSmallDevice ? 155 : 175;

  return (
    <Card
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: "none",
        width: cardWidth, // Adjust width dynamically
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: cardWidth, height: 150, borderRadius: 2 }}
        image={image}
        alt={title}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 0.5,
          width: boxWidth, // Adjust box width dynamically
        }}
      >
        <CardContent
          sx={{
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box flex={1} sx={{ width: "100%", justifyContent: "space-evenly" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: 14, mt: 1, fontWeight: "bold", height: 20 }}
            >
              {title?.charAt(0).toUpperCase() + title?.slice(1)}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: 14, fontWeight: "bold", height: 20 }}
            >
              {tname}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 1,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                color="#A1824A"
                sx={{ fontWeight: "bold", fontSize: 16 }}
              >
                ₹ {price}
              </Typography>
              <Typography
                variant="h6"
                color="#A1824A"
                sx={{ fontWeight: "bold", fontSize: 16 }}
              >
                {measurement} {unit}
              </Typography>
            </Box>
          </Box>
          {isItemInCart ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#019863",
                width: "100%",
                marginTop: 1.2,
                height: 34,
                borderRadius: 1,
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={removeFromCart}
                sx={{ padding: 0,  }}
              >
                <RemoveIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography
                variant="body1"
                sx={{ mx: 1, color: "white", fontWeight: "bold" }}
              >
                {quantity}
              </Typography>
              <IconButton
                onClick={addToCart}
                sx={{ padding: 0, }}
              >
                <AddIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={available ? false : true}
              style={{
                backgroundColor: available ? "#019863" : "#8ab8a8",
                height: 34,
                marginTop: 10,
                width: "100%",
                fontWeight: "bold",
              }}
              sx={{ textTransform: "none" }}
              onClick={addToCart}
            >
              {available ? "Add": "Out of stock"}
            </Button>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default ProductCard;
