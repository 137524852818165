import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import {
  handleLoginAction,
  openLoginModalFunc,
  showMapFunc,
} from "../Redux/reducers/authReducer";

export default function PhoneSignIn() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [code, setCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // Setup reCAPTCHA when the component mounts
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible", // Use invisible reCAPTCHA
        callback: (response) => {
          console.log("Recaptcha resolved", response);
        },
      }
    );

    // Clean up reCAPTCHA when the component unmounts
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear(); // Clear the verifier
        delete window.recaptchaVerifier; // Remove the verifier from global object
      }
    };
  }, []);

  const sendVerificationCode = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+91${phoneNumber}`,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
      setSnackbarMessage("Verification code sent!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error sending verification code:", error);
      setSnackbarMessage("Error sending verification code. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const verifyCode = async (event) => {
    event.preventDefault();
    if (!verificationId || !code) return;

    setLoading(true);
    try {
      const credential = PhoneAuthProvider.credential(verificationId, code);
      const userCredential = await signInWithCredential(auth, credential);
      console.log("User signed in:", userCredential.user);
      dispatch(
        handleLoginAction({
          idToken: userCredential.user?.accessToken,
          phone: phoneNumber,
          uid: userCredential.user?.uid,
        })
      );
      setSnackbarMessage("Verification successful!");
      setSnackbarOpen(true);
      dispatch(openLoginModalFunc(false));
      dispatch(showMapFunc(true));
    } catch (error) {
      console.error("Error verifying code:", error);
      setSnackbarMessage("Error verifying code. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" component="h1" gutterBottom textAlign={"center"}>
        Phone Number Authentication
      </Typography>

      {!verificationId && (
        <Box component="form" onSubmit={sendVerificationCode} noValidate>
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            type="tel"
          />

          <div id="recaptcha-container" style={{ marginBottom: "20px" }}></div>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={loading}
          >
            Send Verification Code
          </Button>
        </Box>
      )}

      {verificationId && (
        <Box component="form" onSubmit={verifyCode} noValidate mt={3}>
          <TextField
            label="Verification Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter the code"
            type="text"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={loading}
          >
            Verify Code
          </Button>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
