// import React from "react";

// const Banner = ({ imageSrc, text }) => {
//   const bannerStyle = {
//     position: "relative",
//     textAlign: "center",
//   };

//   const textStyle = {
//     position: "absolute",
//     top: "20px",
//     left: "20%",
//     transform: "translateX(-50%)",
//     color: "white",
//     fontSize: "2rem",
//     fontWeight: "bold",
//   };

//   return (
//     <div style={bannerStyle}>
//       <img
//         src={require("../assets/banner.jpg")}
//         alt="Banner"
//         style={{ width: "100%", height: "auto" }}
//       />
//       <div style={textStyle}>{"text"}</div>
//     </div>
//   );
// };

// export default Banner;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background: #fff;
`;

const Image = styled.img`
  width: 100%;
  height: auto; // Adjust the height to keep the aspect ratio
  display: block; // Remove default inline spacing
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #fff;
  padding: 10px;
  width: 100%;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 42px;
`;

const Description = styled.p`
  margin: 5px 0;
  font-size: 14px;
`;

const LoginButton = styled.button`
  background-color: #019863;
  padding: 10px;
  color: white;
  font-size: 16px;
  border-color: transparent;
  font-weight: 700;
  border-radius: 10px;
  margin-top: 20px;
  width: 120px;
`;

const ProductCard = () => {
  return (
    <Container>
      <Image
        src={require("../assets/banner1.jpeg")}
        style={{ height: 350 }}
        alt="Product"
      />
      <TextContainer>
        <Title>Freshly Farmed vegetables</Title>
        <Description>
        Discover a convenient way to shop for fresh, high-quality vegetables from the comfort of your home
        </Description>
        {/* <LoginButton>Login</LoginButton> */}
      </TextContainer>
    </Container>
  );
};

export default ProductCard;
