import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch products from the API
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { getState, rejectWithValue }) => {
    const { products } = getState();
    console.log(products.items.length);
    // Check if products are already in the state
    if (products.items.length > 0) {
      console.log("Products already loaded");
      return rejectWithValue("Products already loaded");
    }

    try {
      const response = await axios.get(
        `https://3ffnfchi7j.execute-api.ap-south-1.amazonaws.com/prod/vegetables`
      );
      console.log(response, "product response");
      return response.data.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice
const productSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    loading: false,
    error: null,
    showShimmer: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = false;
        state.error = null;
        state.showShimmer = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = true;
        state.items = action.payload;
        state.showShimmer = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = true;
        if (action.payload !== "Products already loaded") {
          state.error = action.payload;
          state.showShimmer = false;
        }
      });
  },
});

// Export the reducer to be used in the store
export default productSlice.reducer;
