// src/App.js
import React from "react";
import { Provider } from "react-redux";
import RouteNavigation from "./Router/Route";
import store from "./Redux/store/store"; // Import your store

function App() {
  return (
    <Provider store={store}>
      <RouteNavigation />
    </Provider>
  );
}

export default App;
