import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "../Components/CartCard";
import "../cssStyles/commonStyle.scss";
import {
  addToCart,
  addToCartInternalReduxState,
  API_BASE_URL,
  clearCart,
  fetchCart,
  removeFromCartInternalReduxState,
} from "../Redux/reducers/cartReducer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const CartItemsContainer = styled.div`
  flex: 2;
  border-right: 1px solid #ddd;
  padding-right: 20px;
  padding-top: 20px;

  @media (max-width: 768px) {
    border-right: none;
    padding-right: 0;
  }
`;

const OrderSummaryContainer = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 20px;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const CartPage = () => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const [userAddress, setUserAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [doorNum, setDoorNum] = useState("");
  const [streetName, setStreetName] = useState("");
  const [pinCode, setPincode] = useState("");
  const [isPlacingOrder, setIsPlacingOrder] = useState(false); // State for loader
  const navigate = useNavigate();

  const handleSelect = (key) => {
    setSelectedAddress(key);
  };

  useEffect(() => {
    if (cartItems.length <= 0) {
      dispatch(fetchCart());
    }
  }, []);

  const getTotal = () => {
    return (cartItems || []).reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    );
  };

  const handleAddToCart = (product, totalQuantity) => {
    let insertProduct = {
      ...product,
      quantity:
        totalQuantity == undefined
          ? 1
          : totalQuantity === 0
          ? 1
          : totalQuantity + 1,
    };
    dispatch(addToCartInternalReduxState(insertProduct));
  };

  const handleRemoveFromCart = (product, totalQuantity) => {
    let insertProduct = {
      ...product,
      quantity:
        totalQuantity == undefined
          ? 1
          : totalQuantity === 0
          ? 1
          : totalQuantity,
    };
    dispatch(
      removeFromCartInternalReduxState({ insertProduct, totalQuantity })
    );
  };

  const isItemAddedToCart = (productStockId) => {
    const item = cartItems?.find((item) => item.stockId === productStockId);
    return item;
  };

  const findQuantityFromCart = (productStockId) => {
    const item = cartItems?.filter((item) => item.stockId === productStockId);
    return item[0]?.quantity;
  };

  const handlePlaceOrder = async () => {
    setIsPlacingOrder(true); // Set loading state to true
    const placeOrderObj = {
      data: cartItems,
      address: doorNum + ", " + streetName + ", " + pinCode,
      name: name,
      phone: phone,
      total: parseFloat(getTotal().toFixed(2)),
    };
    if (
      name !== "" &&
      doorNum !== "" &&
      phone !== "" &&
      streetName !== "" &&
      pinCode !== ""
    ) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/orders/place`,
          placeOrderObj,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          navigate("/orderSuccess");
          dispatch(clearCart());
        }
      } catch (error) {
        console.error("Error placing order:", error);
      } finally {
        setIsPlacingOrder(false); // Set loading state to false
      }
    } else {
      alert("Please Fill all the inputs to place order");
      setIsPlacingOrder(false);
    }
  };

  return (
    <Container>
      <CartItemsContainer>
        <Title>Cart Items</Title>
        {cartItems?.length > 0 ? (
          cartItems.map((item) => (
            <CartItem
              key={item.title}
              title={item.name}
              price={item.price}
              quantity={item.quantity}
              image={item.image}
              addToCart={() =>
                handleAddToCart(item, findQuantityFromCart(item.stockId))
              }
              removeFromCart={() =>
                handleRemoveFromCart(item, findQuantityFromCart(item.stockId))
              }
              isItemInCart={
                findQuantityFromCart(item.stockId) > 0
                  ? isItemAddedToCart(item.stockId)
                  : false
              }
            />
          ))
        ) : (
          <p>No items in the cart.</p>
        )}
      </CartItemsContainer>
      <OrderSummaryContainer>
        <Title>Order Summary</Title>
        <p>
          Total Items:{" "}
          {cartItems?.reduce((acc, item) => acc + item.quantity, 0) || 0}
        </p>
        <p>Total Price: ₹ {getTotal().toFixed(2)}</p>
        {userAddress?.map((item, key) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: 10,
            }}
          >
            <input
              type="radio"
              checked={selectedAddress === key}
              onChange={() => handleSelect(key)}
              style={{ marginRight: "10px" }}
            />
            <div>{item.address}</div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="order-form-container">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="order-input"
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setPhone(value);
              }}
              className="order-input"
            />
            <input
              type="text"
              placeholder="Flat No / Appartment No"
              value={doorNum}
              onChange={(e) => setDoorNum(e.target.value)}
              className="order-input"
            />
            <input
              type="text"
              placeholder="Street / Locality"
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              className="order-input"
            />
            <input
              type="text"
              placeholder="Pincode"
              value={pinCode}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setPincode(value);
              }}
              className="order-input full-width"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                className="place-order-button"
                onClick={handlePlaceOrder}
                disabled={isPlacingOrder} // Disable button when loading
              >
                {isPlacingOrder ? (
                  <CircularProgress size={15} />
                ) : (
                  "Place Order"
                )}{" "}
                {/* Show loader */}
              </button>
            </div>
          </div>
        </div>
      </OrderSummaryContainer>
    </Container>
  );
};

export default CartPage;
