import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import SubscriptionPlans from "../Pages/Subscription";
import styled from "styled-components";
import ResponsiveAppBar from "../Components/Header";
import HomePage from "../Pages/HomePage";
import CartList from "../Pages/Cart";
import "../App.scss";
import OrderSuccess from "../Components/OrderPlaced";
// Main Layout Wrapper
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 20px; /* Space for Header */
  padding-bottom: 20px; /* Space for Footer */
`;

// Main Content Wrapper
const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto; /* Allow scrolling */
`;

// Header Component
const CustomHeader = () => <ResponsiveAppBar />;

// Layout Component
const Layout = ({ children }) => {
  return (
    <MainWrapper>
      <CustomHeader />
      <ContentWrapper className="content-wrapper">{children}</ContentWrapper>
    </MainWrapper>
  );
};

// Main Router Component
const RouteNavigation = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/Cart"
          element={
            <Layout>
              <CartList />
            </Layout>
          }
        />
        <Route path="/plans" element={<SubscriptionPlans />} />
        <Route path="/orderSuccess" element={<OrderSuccess />} />
      </Routes>
    </Router>
  );
};

export default RouteNavigation;
