import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD9Ni9SWaUFa4Ny51QId5jEn27aIskZzFA",
  authDomain: "green-sack.firebaseapp.com",
  projectId: "green-sack",
  storageBucket: "green-sack.appspot.com",
  messagingSenderId: "1017782708126",
  appId: "1:1017782708126:web:a8a6b564749ca4fdd6188f",
  measurementId: "G-ZQLKFX997T",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
