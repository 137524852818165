import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import PhoneSignIn from "../Pages/PhoneNumberAuthentication";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const CustomModal = ({ open, onClose, onSendOtp }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      hideBackdrop={false}
      closeAfterTransition={true}
      BackdropProps={{
        onClick: (event) => event.stopPropagation(), // Prevent closing on backdrop click
      }}
    >
      <Box sx={modalStyle}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 2 }}>
          <PhoneSignIn />
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
