import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define API URLs
export const API_BASE_URL =
  "https://3ffnfchi7j.execute-api.ap-south-1.amazonaws.com/prod";

// Fetch products from the API
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, { getState, rejectWithValue }) => {
    const { cart } = getState();
    console.log(cart.cartItems.length, "cartssss");
    // Check if products are already in the state
    if (cart.cartItems.length > 0) {
      console.log("cartItems already loaded");
      return rejectWithValue("Products already loaded");
    }

    try {
      let token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/cart`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      console.log(response, "cartItems response");
      return response.data.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (item, { dispatch, rejectWithValue }) => {
    try {
      let token = localStorage.getItem("token");
      console.log(item, token, "cart add");
      // Send the POST request with the Authorization header
      const response = await axios.post(
        `${API_BASE_URL}/cart/add`,
        { data: item.productForPost },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
      if (response.status === 200) {
      }

      return response.data.message;
    } catch (error) {
      console.error("cart post error", error);
    }
  }
);

// Create a slice
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [], // Initial state is an empty cart
    loading: false,
    error: null,
    message: "",
    isCartFetched: false, // This flag will track if the cart has been fetched initially
    addedItemLoading: true,
  },
  reducers: {
    addToCartInternalReduxState(state, action) {
      console.log(action.payload, " action.payload");
      const existingItem = state.cartItems.find(
        (item) => item.stockId === action.payload.stockId
      );

      if (existingItem) {
        console.log(action.payload, "existing item ");
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.stockId === action.payload.stockId
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, { ...action.payload }],
        };
      }
    },
    removeFromCartInternalReduxState(state, action) {
      const existingItem = state.cartItems.find(
        (item) => item.stockId === action.payload.insertProduct.stockId
      );

      if (existingItem) {
        const updatedCartItems = state.cartItems.filter(
          (item) =>
            item.stockId !== action.payload.insertProduct.stockId ||
            item.quantity > 1
        );

        if (action.payload.totalQuantity - 1 === 0) {
          return {
            ...state,
            cartItems: updatedCartItems,
          };
        }

        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.stockId === action.payload.insertProduct.stockId
              ? { ...item, quantity: item.quantity - 1 }
              : item
          ),
        };
      } else {
        // Handle the case where the item is not found in the cart (no change)
        return state;
      }
    },
    clearCart(state) {
      return {
        ...state,
        cartItems: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addedItemLoading = true;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cartItems = action.payload;
        state.isCartFetched = true; // Set the flag to indicate the cart has been fetched
        state.addedItemLoading = false;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.addedItemLoading = false;
      });
  },
});

// Export actions and reducer
export const {
  addToCartInternalReduxState,
  removeFromCartInternalReduxState,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
